import axios, { endpoints } from 'src/utils/axios';

export interface LoginRequest {
  email: string;
  password: string;
}

export interface ResetPasswordRequest {
  password: string;
  token: string;
}

export interface OnboardingRequest {
  password: string;
  token: string;
}

export interface InvitationSignupRequest {
  display_name: string;
  email: string;
  password: string;
  token: string;
}

export default class AuthAPI {
  static async login(creds: LoginRequest) {
    const { data } = await axios.post(endpoints.auth.login, creds);
    return data;
  }

  static async register(creds: LoginRequest & { firstName: string; lastName: string }) {
    const { data } = await axios.post(endpoints.auth.register, creds);
    return data;
  }

  static async invitationSignup(creds: InvitationSignupRequest) {
    const { data } = await axios.post(endpoints.auth.invitationSignup, creds);
    return data;
  }

  static async beginPasswordReset(email: string) {
    const { data } = await axios.post(endpoints.auth.beginResetPassword, { email });
    return data;
  }

  static async resetPassword(creds: ResetPasswordRequest) {
    const { data } = await axios.post(endpoints.auth.resetPassword, creds);
    return data;
  }

  static async onboardUser(body: OnboardingRequest) {
    const { data } = await axios.post(endpoints.auth.onboard, body);
    return data;
  }

  static async userProfile() {
    const { data } = await axios.get(endpoints.auth.me);
    return data;
  }
}
