// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  USER: '/user',
  PRODUCT_HUB: '/product-hub',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/login`,
      register: `${ROOTS.AUTH}/register`,
      forgot: `${ROOTS.AUTH}/forgot`,
      reset: `${ROOTS.AUTH}/reset`,
      onboard: `${ROOTS.AUTH}/onboard`,
      invitationSignup: `${ROOTS.AUTH}/invitation-signup`,
    },
  },

  // DASHBOARD
  dashboard: {
    root: `${ROOTS.DASHBOARD}/adverse-events`,
    recalls: `${ROOTS.DASHBOARD}/recalls`,
    profile: `${ROOTS.DASHBOARD}/profile`,
    complaints: `${ROOTS.DASHBOARD}/complaints`,
    mapping: `${ROOTS.DASHBOARD}/mapping`,
    harms: `${ROOTS.DASHBOARD}/harms`,
    pms: `${ROOTS.DASHBOARD}/pms`,
    psu: `${ROOTS.DASHBOARD}/psu`,
    lit: `${ROOTS.DASHBOARD}/lit`,
    cer: `${ROOTS.DASHBOARD}/cer`,
    reportability: `${ROOTS.DASHBOARD}/reportability`,
    productInfo: `${ROOTS.DASHBOARD}/product-information`,
    patientComplaints: `${ROOTS.DASHBOARD}/patient-complaints`,
    risk: `${ROOTS.DASHBOARD}/risk`,
    clinicalLiterature: `${ROOTS.DASHBOARD}/clinical-literature`,
    investigation: `${ROOTS.DASHBOARD}/investigation`,
  },

  // USER
  user: {
    profile: `${ROOTS.USER}/profile`,
    people: `${ROOTS.USER}/manage-people`,
  },
  // Product hub
  product_hub: {
    root: `${ROOTS.PRODUCT_HUB}`,
  },
};
