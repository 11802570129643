import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { alpha, styled } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';

import { RouterLink } from 'src/routes/components';

import { NavItemProps, NavItemStateProps } from '../types';

// ----------------------------------------------------------------------

const NavItem = forwardRef<HTMLDivElement, NavItemProps>(
  (
    {
      title,
      path,
      icon,
      disabled,
      //
      active,
      externalLink,
      ...other
    },
    ref
  ) => {
    const renderContent = (
      <StyledNavItem disableGutters ref={ref} active={active} disabled={disabled} {...other}>
        {icon && (
          <Box component="span" className="icon">
            {icon}
          </Box>
        )}

        {title && (
          <Box component="span" className="label">
            {title}
          </Box>
        )}
      </StyledNavItem>
    );

    if (externalLink)
      return (
        <Link
          href={path}
          target="_blank"
          rel="noopener"
          color="inherit"
          underline="none"
          sx={{
            width: 1,
            ...(disabled && {
              cursor: 'default',
            }),
          }}
        >
          {renderContent}
        </Link>
      );

    return (
      <Link
        component={RouterLink}
        href={path}
        color="inherit"
        underline="none"
        sx={{
          width: 1,
          ...(disabled && {
            cursor: 'default',
          }),
        }}
      >
        {renderContent}
      </Link>
    );
  }
);

export default NavItem;

// ----------------------------------------------------------------------

const StyledNavItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})<NavItemStateProps>(({ active, theme }) => {
  const lightMode = theme.palette.mode === 'light';

  const noWrapStyles = {
    width: '100%',
    maxWidth: '100%',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  } as const;

  const baseStyles = {
    item: {
      color: theme.palette.text.secondary,
    },
    icon: {
      width: 24,
      height: 24,
      flexShrink: 0,
    },
  } as const;

  return {
    // Root item
    ...baseStyles.item,
    fontSize: 11,
    minHeight: 56,
    lineHeight: '16px',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(0.5),
    margin: theme.spacing(0, 1),
    fontWeight: theme.typography.fontWeightMedium,
    borderRadius: 6,
    '& .icon': {
      ...baseStyles.icon,
    },
    '& .label': {
      ...noWrapStyles,
      marginTop: theme.spacing(0.5),
    },
    '& .arrow': {
      top: 11,
      right: 6,
      position: 'absolute',
    },
    ...(active && {
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      color: lightMode ? '#004B95' : theme.palette.primary.light,
      boxShadow: `0 1px 4px ${alpha(theme.palette.primary.main, 0.4)}`,
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.16),
      },
    }),
  };
});
