import { useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

type ReturnType = boolean;

export function useActiveLink(path: string, deep = true): ReturnType {
  const location = useLocation();

  const fullPath = `${location.pathname}${location.search}`;

  return fullPath.startsWith(path);

  // const normalActive = path ? !!matchPath({ path, end: false }, fullPath) : false;

  // const deepActive = path ? !!matchPath({ path, end: false }, fullPath) : false;

  // return deep ? deepActive : normalActive;
}
