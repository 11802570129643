import React, { useReducer } from 'react';

//

import { maudeState, maudeRootReducer } from '../store/maude';

//

import { MDContext } from './maude-context';

interface MaudeProviderProps {
  children: React.ReactNode;
}

export const MaudeProvider: React.FC<MaudeProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(maudeRootReducer, maudeState);

  const memoizedMaude = React.useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return <MDContext.Provider value={memoizedMaude}>{children}</MDContext.Provider>;
};
