import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import { useTheme } from '@mui/material/styles';

import { useResponsive } from 'src/hooks/use-responsive';

import { bgBlur } from 'src/theme/css';

import InviteUsersForm from 'src/components/invite-users-form';

import TenantBar from '../common/tenant-bar';
import { NAV, HEADER } from '../config-layout';
import AccountPopover from '../common/account-popover';

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');
  const lgDown = useResponsive('down', 'lg');

  const renderContent = (
    <Stack
      flexGrow={1}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={{ xs: 0.5, sm: 1 }}
    >
      <TenantBar />
      <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 2 }}>
        <InviteUsersForm hasSoftButton />
        <AccountPopover />
      </Stack>
    </Stack>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: 40,
        ...bgBlur({
          color: '#004B95',
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(mdUp && {
          width: `calc(100% - ${NAV.W_MINI - 1}px)`,
          height: HEADER.H_DESKTOP,
        }),
      }}
    >
      {lgDown && (
        <Alert severity="error" variant="filled" sx={{ zIndex: 100, borderRadius: 0 }}>
          Empowerreg can be better experienced on laptop or desktop screens
        </Alert>
      )}

      <Stack
        sx={{
          height: 1,
          px: { lg: 3 },
        }}
      >
        {renderContent}
      </Stack>
    </AppBar>
  );
}
