import axios, { endpoints } from 'src/utils/axios';

export interface TenantInfo {
  id: string;
  name: string;
  status: string;
  metadata: {
    onboarding_status: string;
    default_procodes: {
      id: string;
    }[];
    manufacturers: {
      id: string;
    }[];
    default_competitors: {
      id: string;
    }[];
  };
}

export interface Plan {
  id: string;
  name: string;
  description: string;
  smart_viz: boolean;
  intellirisk: boolean;
  nca: boolean;
  competitive_intelligence: boolean;
  insights: boolean;
  datasources: boolean;
  product_hub: boolean;
}

export default class TenantAPI {
  static async getTenantInfo(token = null): Promise<TenantInfo> {
    if (token) {
      const { data } = await axios.get(endpoints.tenants.info, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    }
    const { data } = await axios.get(endpoints.tenants.info);
    return data;
  }

  static async getTenantPlan(token = null): Promise<Plan> {
    if (token) {
      const { data } = await axios.get(endpoints.tenants.plan, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    }
    const { data } = await axios.get(endpoints.tenants.plan);
    return data;
  }
}
