import axios, { endpoints } from 'src/utils/axios';

export class InvitesAPI {
  static async inviteNewUser(emails: string[]) {
    const formattedEmails = emails.map((email) => ({ email }));

    const { data } = await axios.post(endpoints.invites.new, { to: formattedEmails });
    return data;
  }
}
