import * as Yup from 'yup';
import { useState } from 'react';

//

import { yupResolver } from '@hookform/resolvers/yup';

//

import { LoadingButton } from '@mui/lab';

//

import AddIcon from '@mui/icons-material/Add';

//

import { useMutation, useQueryClient } from '@tanstack/react-query';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { InvitesAPI } from 'src/api/invites';

//

import { useForm } from 'react-hook-form';

import { Transition } from '../transition';

//

import { RHFTextField } from '../common/hook-form';
import FormProvider from '../common/hook-form/form-provider';

export default function InviteUsersForm({ hasSoftButton = false }: { hasSoftButton?: boolean }) {
  const [emailList, setEmailList] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const InviteSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  });

  const defaultValues = {
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(InviteSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(async (data) => {
    if (!data.email) return;

    if (emailList.includes(data.email)) return;

    setEmailList((prev) => [...prev, data.email]);

    methods.reset();
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDelete = (email: string) => {
    setEmailList((prev) => prev.filter((item) => item !== email));
  };

  const handleClose = () => {
    methods.reset();
    setOpen(false);
  };

  const sendInvites = async () => {
    if (!emailList.length) return;

    try {
      await InvitesAPI.inviteNewUser(emailList);
    } catch (error) {
      console.error(error);
    } finally {
      setEmailList([]);
      handleClose();
    }
  };
  const queryClient = useQueryClient();
  const sendInviteMutation = useMutation({
    mutationFn: sendInvites,
    onSuccess: (data) => {
      setEmailList([]);
      handleClose();
      queryClient.invalidateQueries({ queryKey: ['get_pending_invites'] });
    },
  });

  return (
    <>
      <Button
        size={hasSoftButton ? 'small' : 'medium'}
        variant={hasSoftButton ? 'text' : 'contained'}
        onClick={handleClickOpen}
        disabled={sendInviteMutation.isPending}
        color={hasSoftButton ? 'inherit' : 'primary'}
        sx={{ color: 'white' }}
      >
        Invite People
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
      >
        <DialogTitle sx={{ borderBottom: '1px solid #eee', py: 2 }}>
          Invite your teammates
        </DialogTitle>
        <DialogContent sx={{ py: 3 }}>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Typography variant="body2" color="gray" sx={{ my: 2 }}>
              Each member will be sent an invite link to join your team.
            </Typography>
            <Stack spacing={2} direction="row">
              <RHFTextField
                name="email"
                label="Email address"
                color="primary"
                variant="outlined"
                placeholder="john@example.com"
                size="small"
              />
            </Stack>
            <Button
              size="small"
              variant="outlined"
              sx={{ mt: 1 }}
              type="submit"
              endIcon={<AddIcon />}
            >
              Add new teammate
            </Button>
          </FormProvider>
          {emailList && (
            <Stack direction="row" flexWrap="wrap" spacing={1} sx={{ mt: 2 }}>
              {emailList.map((email, index) => (
                <Chip
                  variant="outlined"
                  label={email}
                  key={index}
                  onDelete={() => handleDelete(email)}
                />
              ))}
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="soft">
            Close
          </Button>
          <LoadingButton
            onClick={() => sendInviteMutation.mutate()}
            variant="contained"
            color="primary"
            loading={sendInviteMutation.isPending}
            disabled={!emailList.length}
          >
            Send Invite
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
