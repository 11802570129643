import overlayImg from '@assets/background/overlay_4.jpg';

//

import Stack from '@mui/material/Stack';
import { alpha, useTheme } from '@mui/material/styles';

import { bgGradient } from 'src/theme/css';

import Logo from 'src/components/common/logo';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthClassicLayout({ children }: Props) {
  const theme = useTheme();

  const renderLogo = <Logo />;

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        maxWidth: 440,
        px: 3,
        py: 3,
        bgcolor: 'background.paper',
        boxShadow: theme.shadows[10],
        minHeight: { xs: '100vh', md: 'auto' },
        borderRadius: {
          xs: 0,
          md: 1,
        },
      }}
    >
      {renderLogo}
      {children}
    </Stack>
  );

  return (
    <Stack
      component="main"
      sx={{
        display: 'flex',
        alignItems: { md: 'center' },
        justifyContent: { md: 'center' },
        minHeight: '100vh',
        ...bgGradient({
          color: alpha(theme.palette.background.default, theme.palette.mode === 'light' ? 0 : 0.7),
          imgUrl: overlayImg,
        }),
      }}
    >
      {renderContent}
    </Stack>
  );
}
