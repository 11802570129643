import { getPartnerLabel, getCompetitorLabel } from 'src/config';

import { MaudeData, CompanyData, MaudeActions } from 'src/types/maude-types';

const companyData: CompanyData = {
  name: getPartnerLabel(),
  page: 1,
  total_rows: 0,
  order_by: [],
};

const competitorData = {
  ...companyData,
  name: getCompetitorLabel(),
};

export const maudeState: MaudeData = {
  cnf: {
    current: {
      procode: '',
      start_date: '',
      end_date: '',
      timePeriod: '',
    },
    us: [],
    them: [],
    filters: [],
    competitorViewEnabled: false,
    chartPanelEnabled: false,
  },
  us: companyData,
  them: competitorData,
};

export const maudeRootReducer = (state: MaudeData, action: MaudeActions): MaudeData => {
  switch (action.type) {
    case 'SET_PROCODE':
      return {
        ...state,
        cnf: {
          ...state.cnf,
          current: {
            ...state.cnf.current,
            procode: action.payload,
          },
        },
      };
    case 'RESET_CURRENT_PROCODE':
      return {
        ...state,
        cnf: {
          ...state.cnf,
          current: {
            ...state.cnf.current,
            procode: '',
          },
        },
      };
    case 'SET_TIME_PERIOD':
      return {
        ...state,
        cnf: {
          ...state.cnf,
          current: {
            ...state.cnf.current,
            timePeriod: action.payload.timePeriod,
            start_date: action.payload.start_date,
            end_date: action.payload.end_date,
          },
        },
      };
    case 'RESET_DATA_ONLY':
      return {
        cnf: {
          ...state.cnf,
        },
        us: {
          ...companyData,
        },
        them: {
          ...competitorData,
        },
      };
    case 'ENABLE_COMPETITOR_VIEW':
      return {
        ...state,
        cnf: {
          ...state.cnf,
          competitorViewEnabled: action.payload,
        },
      };
    case 'ENABLE_CHART_PANEL':
      return {
        ...state,
        cnf: {
          ...state.cnf,
          chartPanelEnabled: action.payload,
        },
      };
    case 'SET_MAUDE_FILTERS':
      return {
        ...state,
        cnf: {
          ...state.cnf,
          filters: action.payload.filters,
          us: action.payload.us,
          them: action.payload.them,
        },
      };
    case 'US_SET_COMPANY_NAME':
      return {
        ...state,
        us: {
          ...state.us,
          name: action.payload,
        },
      };
    case 'TM_SET_COMPANY_NAME':
      return {
        ...state,
        them: {
          ...state.them,
          name: action.payload,
        },
      };
    case 'US_SET_PAGINATION':
      return {
        ...state,
        us: {
          ...state.us,
          page: action.payload.page,
          total_rows: action.payload.total_rows,
        },
      };
    case 'TM_SET_PAGINATION':
      return {
        ...state,
        them: {
          ...state.them,
          page: action.payload.page,
          total_rows: action.payload.total_rows,
        },
      };
    case 'US_SET_ORDER_BY':
      return {
        ...state,
        us: {
          ...state.us,
          order_by: action.payload,
        },
      };
    case 'TM_SET_ORDER_BY':
      return {
        ...state,
        them: {
          ...state.them,
          order_by: action.payload,
        },
      };
    default:
      return state;
  }
};
