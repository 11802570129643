import Typography from '@mui/material/Typography';

import { useAuthContext } from 'src/auth/hooks';

export default function TenantBar() {
  const { tenant } = useAuthContext();

  if (!tenant) return null;

  return (
    <Typography variant="overline" component="p" sx={{ color: 'white' }} data-testid="tenantName">
      {tenant.name}
    </Typography>
  );
}
