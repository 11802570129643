import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { GuestGuard } from 'src/auth/guard';
import AuthClassicLayout from 'src/layouts/auth/classic';

import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const OnboardingPasswordPage = lazy(() => import('src/pages/auth/jwt/onboard'));
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));
const JwtForgotPage = lazy(() => import('src/pages/auth/jwt/forgot'));
const JwtResetPasswordView = lazy(() => import('src/pages/auth/jwt/reset'));
const JwtInvitationSignupPage = lazy(() => import('src/pages/auth/jwt/invitationSignup'));

// ----------------------------------------------------------------------

const authJwt = {
  element: (
    <Suspense fallback={<SplashScreen />}>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      path: 'login',
      element: (
        <GuestGuard>
          <AuthClassicLayout>
            <JwtLoginPage />
          </AuthClassicLayout>
        </GuestGuard>
      ),
    },
    {
      path: 'register',
      element: (
        <GuestGuard>
          <AuthClassicLayout>
            <JwtRegisterPage />
          </AuthClassicLayout>
        </GuestGuard>
      ),
    },
    {
      path: 'forgot',
      element: (
        <GuestGuard>
          <AuthClassicLayout>
            <JwtForgotPage />
          </AuthClassicLayout>
        </GuestGuard>
      ),
    },
    {
      path: 'reset',
      element: (
        <GuestGuard>
          <AuthClassicLayout>
            <JwtResetPasswordView />
          </AuthClassicLayout>
        </GuestGuard>
      ),
    },
    {
      path: 'onboard',
      element: (
        <GuestGuard>
          <AuthClassicLayout>
            <OnboardingPasswordPage />
          </AuthClassicLayout>
        </GuestGuard>
      ),
    },
    {
      path: 'invitation-signup',
      element: (
        <GuestGuard>
          <AuthClassicLayout>
            <JwtInvitationSignupPage />
          </AuthClassicLayout>
        </GuestGuard>
      ),
    },
  ],
};

export const authRoutes = [
  {
    path: 'auth',
    children: [authJwt],
  },
];
