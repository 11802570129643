import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

const UserProfile = lazy(() => import('src/pages/user/profile'));
const ManagePeople = lazy(() => import('src/pages/user/manage-people'));

export const userRoutes = [
  {
    path: 'user',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: '', // Empty path for /user route
        element: <Navigate to="profile" />,
      },
      {
        path: 'profile',
        element: <UserProfile />,
      },
      {
        path: 'manage-people',
        element: <ManagePeople />,
      },
    ],
  },
];
