import { Dispatch, useContext, createContext } from 'react';

import { MaudeData, MaudeActions } from 'src/types/maude-types';

export interface MaudeContextType {
  state: MaudeData;
  dispatch: Dispatch<MaudeActions>;
}

export const MDContext = createContext<MaudeContextType | undefined>(undefined);

export function useMDContext() {
  const mdContext = useContext(MDContext);
  if (!mdContext) {
    throw new Error('Maude context must be used within a Maude Provider');
  }

  return mdContext;
}
