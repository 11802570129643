import { useMemo } from 'react';

import QueryStatsIcon from '@mui/icons-material/QueryStats';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';

import AuthAPI from 'src/api/auth';
import TenantsAPI from 'src/api/tenant';
import DBIcon from 'src/assets/icons/db';
import CloudIcon from 'src/assets/icons/cloud';
import DangerIcon from 'src/assets/icons/danger';

//

import { useQuery } from '@tanstack/react-query';

import { paths } from 'src/routes/paths';

// ---------------------------------------------

const ICONS = {
  storage: <DBIcon />,
  intel: <CloudIcon />,
  risk: <DangerIcon />,
  hub: <HubOutlinedIcon />,
  query: <QueryStatsIcon />,
  doc: <AssessmentOutlinedIcon />,
  analytics: <TrendingUpOutlinedIcon />,
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { data: tenantPlan } = useQuery({
    queryKey: ['get_tenant_plan'],
    queryFn: () => TenantsAPI.getTenantPlan(),
  });
  const data = useMemo(() => {
    const routes = [
      {
        subheader: 'Menu',
        items: [
          {
            title: 'Product Hub',
            path: `${tenantPlan?.product_hub ? paths.product_hub.root : '#'}`,
            icon: ICONS.hub,
            disabled: !tenantPlan?.product_hub,
          },
          {
            title: 'Smart Viz and Analytics',
            path: `${paths.dashboard.root}`,
            icon: ICONS.query,
            disabled: !tenantPlan?.smart_viz,
          },
          {
            title: 'IntelliRisk™',
            path: `#`,
            icon: ICONS.risk,
            customMsg: 'Coming Soon',
            disabled: !tenantPlan?.intellirisk,
          },
          {
            title: 'Nonconformance Analytics',
            path: `#`,
            icon: ICONS.analytics,
            customMsg: 'Coming Soon',
            disabled: !tenantPlan?.nca,
          },
          {
            title: 'Competitive Intelligence',
            path: `#`,
            icon: ICONS.intel,
            customMsg: 'Coming Soon',
            disabled: !tenantPlan?.competitive_intelligence,
          },
          {
            title: 'Insights and Reports',
            path: `#`,
            icon: ICONS.doc,
            customMsg: 'Coming Soon',
            disabled: !tenantPlan?.insights,
          },
          {
            title: 'Data Sources',
            path: `#`,
            icon: ICONS.storage,
            customMsg: 'Coming Soon',
            disabled: !tenantPlan?.datasources,
          },
        ],
      },
    ];
    return routes;
  }, [tenantPlan]);

  return data;
}
